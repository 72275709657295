<template>

    <div class="account-pages pb-4  position-relative" style="padding-top: 18rem;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xxl-8 col-lg-10">
                    <div class="card overflow-hidden">
                        <div class="row g-0">
                            <div class="col-lg-6 d-none d-lg-block p-2">
                                <img src="../assets/img/auth-img.jpg" alt="" class="img-fluid rounded h-100">
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex flex-column h-100">
                                    <div class="auth-brand p-4">
                                    
                                        <a  class="logo-dark" style="font-weight: bold;font-size: 32px;">
                                            二手旧衣物回收平台
                                        </a>
                                    </div>
                                    <div class="p-4 my-auto">
                                        <h4 class="fs-20">登录入口</h4>
                                        <p class="text-muted mb-3">请输入您的账号和密码后登录
                                        </p>
                                             <form   id="mform">
			<div class="mb-3">
				<label for="emailaddress" class="form-label">您的账号：</label>
				<input class="form-control" type="text" id="emailaddress" required="" v-model="loginModel.username"  
					placeholder="请输入您的账号">
			</div>
			<div class="mb-3">
		   
				<label for="password" class="form-label">您的密码：</label>
				<input class="form-control" type="password" required="" id="password"
				v-model="loginModel.password"	placeholder="请输入您的密码">
			</div>
			    
			<div class="mb-0 text-start">
				<button class="btn btn-soft-primary w-100" type="submit"  @click.prevent="login"><i
						class="ri-login-circle-fill me-1"></i> <span class="fw-bold">登录</span> </button>
			</div>

		</form>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
<!--                    <p class="text-dark-emphasis">您还没有账号？ <a href="auth-register.html"
                            class="text-dark fw-bold ms-1 link-offset-3 text-decoration-underline"><b>点击注册</b></a>
                    </p>-->
                </div>
            </div>
        </div>
    </div>
 
</template>


<script>
import request, { base } from "../../utils/http";
export default {
  name: "Login",
  data() {
    return {
      year: new Date().getFullYear(),
      loginModel: {
        username: "",
        password: "",
        radio: "管理员",
      },
      loginModel2: {},
     
    };
  },
  mounted() {},
  created() {
    
  },
  methods: {
    login() {
      let that = this;  

      if (that.loginModel.username == "") {
        that.$message({
          message: "请输入账号",
          type: "warning",
        });
        return;
      }
      if (that.loginModel.password == "") {
        that.$message({
          message: "请输入密码",
          type: "warning",
        });
        return;
      }   
      
      this.loading = true;
           let url = base + "/admin/login";
      this.loginModel2.adminaccount = this.loginModel.username;
      this.loginModel2.adminpassword = this.loginModel.password;
      request.post(url, this.loginModel2).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          console.log(JSON.stringify(res.resdata));
          sessionStorage.setItem("user", JSON.stringify(res.resdata));
          sessionStorage.setItem("userLname", res.resdata.adminaccount);
          sessionStorage.setItem("role", "管理员");
          this.$router.push("/main");
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    
     
    },
    
    
  },
};
</script>


<style>
@import url(../assets/css/app.min.css);
@import url(../assets/css/icons.min.css);

body{
            background-image: url(../assets/img/auth-bg.jpg);
            background-size: cover;
 }
        .form-control{       
            border: 1px solid #3bc0c3;
        }
        .form-control:focus{
            border: 2px solid #c01750;
            box-shadow: none;
        }
</style>




